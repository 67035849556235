import React, { useRef } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "components/Layout";
import SEO from "components/seo";
import SkewedRectangle from "assets/skewed-rectange.local.svg";
import Dot from "assets/dot.local.svg";
// import Testimonials from "components/Testimonials";
import Team from "components/Team";
import IndustryRestuarantIcon from "assets/industry-restuarants.local.svg";
import IndustryRetailStoresIcon from "assets/industry-retail-stores.local.svg";
import IndustryBillsIcon from "assets/industry-bills.local.svg";
import IndustryCabsIcon from "assets/industry-cabs.local.svg";
import IndustryTheatersIcon from "assets/industry-theaters.local.svg";

function IndexPage({ data }) {
  const steps = [
    {
      title: "Enter purchase price",
      image: data.howToStep1
    },
    {
      title: "Tap your customer card to the back of your phone",
      image: data.howToStep2
    },
    {
      title: "Tadaa! Payment collected",
      image: data.howToStep3
    }
  ];

  const benefits = [
    {
      title: "Contactless payments",
      description:
        "Accept payments using NFC technology which is already present in contactless credit/debit cards.",
      image: data.nfcImage
    },
    {
      title: "Prepaid wallets support",
      description:
        "It supports closed loop prepaid wallets like BHIM, GPay, Paytm, Phonepe etc over NFC ",
      image: data.walletsImage
    },
    {
      title: "Save 90% of all costs",
      description:
        "Reduce the installation, operational and capital costs in setting up a digital payment system",
      image: data.savingsImage
    }
  ];

  const industries = [
    { title: "Restaurants", icon: IndustryRestuarantIcon },
    { title: "Retail Stores", icon: IndustryRetailStoresIcon },
    { title: "Bills", icon: IndustryBillsIcon },
    { title: "Cabs", icon: IndustryCabsIcon },
    { title: "Theaters", icon: IndustryTheatersIcon }
  ];

  const refs = {
    "#how-to-accept-payment": useRef(null),
    "#benefits": useRef(null)
  };

  const handleMenuClick = e => {
    const { hash } = new URL(e.target.href);
    const el = refs[hash];

    if (el && el.current) {
      el.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Layout onMenuClick={handleMenuClick}>
      <SEO
        keywords={[
          `contactless`,
          `payments`,
          `fintech`,
          `India`,
          `UPoS`,
          `Uvik Technologies`
        ]}
      />

      <section className="pt-32 lg:pt-48 px-5">
        <div className="max-w-6xl bg-pink-30 py-8 px-5 lg:py-16 lg:px-16 mx-auto rounded-5 lg:rounded-10 bg-contain bg-center box-with-pattern min-h-74 relative">
          <div className="flex flex-col space-y-5 md:space-y-0 md:grid md:grid-cols-2 lg:grid-cols-3">
            <div className="lg:col-span-2" data-aos="fade-up">
              <h1 className="text-xl font-bold lg:text-5xl leading-7 md:leading-tight text-pink-50">
                Collect your payments Contactless{" "}
              </h1>
              <p className="text-black-10 leading-6 text-sm font-semibold lg:text-xl mt-1 lg:mt-6">
                No dongles. No cables. No extra devices. <br /> Just your
                smartphone.
              </p>
              <Link
                to="#how-to-accept-payment"
                className="mt-6 md:mt-8 text-pink-50 font-bold text-lg hidden md:block"
                onClick={handleMenuClick}
              >
                See how it works &raquo;
              </Link>
            </div>

            <div className="relative lg:static">
              <div
                className="absolute lg:top-0 lg:-mt-9/2"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <Img
                  fluid={data.heroImage.childImageSharp.fluid}
                  alt="The Contactless Payments Image"
                  className="w-60 lg:w-88"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-32 lg:mt-40 px-5 relative overflow-hidden">
        <div
          className="max-w-6xl mx-auto box-with-pattern-alt overflow-hidden"
          data-aos="fade-up"
        >
          <div className="flex flex-col space-y-5 py-8 lg:py-20 lg:items-center lg:grid lg:grid-cols-5 lg:gap-x-12 relative">
            <div className="lg:col-span-2">
              <h2 className="text-xl font-bold lg:text-3xl leading-7 md:leading-tight text-pink-50 uppercase">
                No additional devices Use your smartphone
              </h2>
            </div>
            <div className="text-black text-sm leading-6 lg:text-xl mt-2 lg:mt-6 space-y-6 lg:col-span-3">
              <p>
                Whatever you do — flower business, restuarant or beverage sales
                — you know for sure that more and more customers prefer
                contactless payment methods.
              </p>
              <p>
                Offer your customers a fast and familiar way to pay using
                bankcards, smart phones, payment apps and other wearable NFC
                devices, such as smart watches, rings and bands.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="pt-24 lg:pt-40 px-5"
        id="how-to-accept-payment"
        ref={refs["#how-to-accept-payment"]}
      >
        <div
          className="max-w-6xl mx-auto py-8 px-5 md:py-16 rounded-5 md:rounded-20 box-with-pattern-howto relative"
          data-aos="fade-up"
        >
          <div className="relative">
            <h2 className="text-xl font-bold md:text-5xl text-center leading-7 md:leading-tight text-pink-50 uppercase">
              How to accept payment?
            </h2>

            <ul className="mt-5 flex space-x-5 md:justify-center lg:mt-10 lg:space-x-16 overflow-x-scroll overflow-y-hidden">
              {steps.map((step, index) => (
                <li
                  className="flex flex-col space-y-6 justify-between md:items-center w-40 lg:w-56"
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-delay={index * 100}
                >
                  <div className="space-y-3 flex flex-col md:items-center">
                    <div className="w-6 h-6 lg:w-12 lg:h-12 rounded-full border text-center text-sm font-semibold step-counter lg:text-lg flex items-center justify-center">
                      {index + 1}
                    </div>
                    <h3 className="text-xs font-semibold md:max-w-3/4 lg:text-center lg:text-lg">
                      {step.title}
                    </h3>
                  </div>
                  <Img
                    className="w-40 lg:w-56"
                    fluid={step.image.childImageSharp.fluid}
                    alt="Image of UPOS App showing Enter Amount screen"
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section
        className="pt-24 lg:pt-40 px-5 relative overflow-hidden"
        id="benefits"
        ref={refs["#benefits"]}
      >
        <div
          className="max-w-6xl mx-auto px-5 rounded-5 md:rounded-20 flex flex-col lg:flex-row lg:space-x-8 box-with-pattern-alt"
          data-aos="fade-up"
        >
          <div className="relative flex flex-col lg:flex-row lg:items-center lg:space-x-6 py-8 lg:py-20 ">
            <h2 className="text-xl font-bold md:text-4xl leading-7 md:leading-tight text-pink-50 uppercase">
              Benefits of UPOS
            </h2>

            <ul className="mt-2 flex space-x-5 overflow-x-scroll ">
              {benefits.map((benefit, index) => (
                <li
                  key={index}
                  className="mt-5 bg-white py-6 lg:px-4 relative rounded-5 w-4/5 flex-shrink-0 lg:flex-shrink"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-delay={index * 100}
                >
                  <div className="flex flex-col justify-center items-center relative space-y-8">
                    <Img
                      fluid={benefit.image.childImageSharp.fluid}
                      className="w-20 lg:w-36"
                      alt="Contactless Payments"
                    />
                    <div className="space-y-2 text-center">
                      <h3 className="font-semibold md:text-xl">
                        {benefit.title}
                      </h3>
                      <p className="text-sm leading-relaxed">
                        {benefit.description}
                      </p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="py-24 lg:py-48 px-5">
        <div
          className="max-w-6xl bg-pink-30 py-8 px-5 lg:py-16 lg:px-16 mx-auto rounded-5 lg:rounded-10 bg-contain bg-center box-with-pattern min-h-74 relative transform-none"
          data-aos="fade-up"
        >
          <div className="flex flex-col space-y-5 lg:space-y-12">
            <h2 className="text-xl font-bold lg:text-5xl leading-7 md:leading-tight text-pink-50 uppercase text-center w-3/4 mx-auto">
              Few industries we cater to
            </h2>

            <ul className="grid grid-cols-2 gap-6 md:flex md:flex-wrap items-center justify-around">
              {industries.map((industry, index) => (
                <li
                  key={index}
                  className="flex flex-col items-center"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-delay={index * 50}
                >
                  <industry.icon className="w-16" />
                  {industry.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      {/* <section */}
      {/*   className="py-16 md:py-24 px-4 text-center bg-white relative overflow-hidden" */}
      {/*   id="testimonials" */}
      {/*   data-aos="fade-up" */}
      {/* > */}
      {/*   <Testimonials testimonials={data.allTestimonialsJson.edges} /> */}
      {/* </section> */}

      {/* <section */}
      {/*   className="bg-gradient-primary-invert py-16 md:py-32 px-6 text-center" */}
      {/*   id="team" */}
      {/* > */}
      {/*   <div className="max-w-5xl mx-auto" data-aos="fade-up"> */}
      {/*     <Team members={data.allTeamJson.edges} /> */}
      {/*   </div> */}
      {/* </section> */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query HomeQuery {
    heroImage: file(name: { eq: "uvik-hero-v2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    howToStep1: file(name: { eq: "how-to-1" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    howToStep2: file(name: { eq: "how-to-2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    howToStep3: file(name: { eq: "how-to-3" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nfcImage: file(name: { eq: "nfc" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    walletsImage: file(name: { eq: "wallets" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    savingsImage: file(name: { eq: "savings" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsSeason2Image: file(name: { eq: "news-s2" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsSeason2ImageDesktop: file(name: { eq: "news-s2-d" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allTestimonialsJson {
      edges {
        node {
          id
          name
          company
          testimonial
        }
      }
    }

    allTeamJson {
      edges {
        node {
          name
          designation
          linkedinUrl
          avatar {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
